.animation--slide-up {
    transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
    transform: translateY(100px);
    opacity: 0.0;

    &.animation--active {
        transform: translateY(0px);
        opacity: 1.0;
    }
}

.animation--slide-left {
    transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
    transform: translateX(100px);
    opacity: 0.0;

    &.animation--active {
        transform: translateX(0px);
        opacity: 1.0;
    }
}

.animation--slide-right {
    transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
    transform: translateX(-100px);
    opacity: 0.0;

    &.animation--active {
        transform: translateX(0px);
        opacity: 1.0;
    }
}

